*{
    text-decoration: none !important;
    outline: 0 !important;
    font-family: 'gudea' !important;
}
@font-face {
    font-family: gudea;
    src: url("../Fonts/Gudea-Regular.ttf"); /* TTF file for CSS3 browsers */
}
body {
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
    /* position: relative; */
    /* margin: 0 auto; */
    font-family: 'gudea' !important;
    background: #f1f1f1 !important;
}
.container-in {
    max-width: 1100px;
    margin: 0 auto;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}
.navbar {
    padding: 8px 10px;
    background: #ffff;
    border-bottom: 1px solid #ec5e69;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    height: 57px;
    display: block;
}
.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
}
#sidebar {
    width: 210px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: #ffffff;
    color: #ec1c24;
    transition: all 0.3s;
}
#dropdownNotificaciones{
    color: #fff!important;
    background: #bcbcbc2e;
}
#sidebar.active {
    margin-left: -250px;
}
#sidebar .sidebar-header {
    padding: 0;
    background: #ffffff;
    height: 57px;
    border-bottom: 1px solid #ec5e69;
}
.width_img {
    width: 200px;
    float: left;
    margin: auto;
    /* padding: 2px; */
}
.width_img_mov {
    float: left;
    margin: auto;
    padding: 11px;
    /* margin-top : 25px; */
    margin-top : 10px;
}
#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #ffffff;
}

#sidebar ul p {
    color: #fff;
    padding: 10px 30px;
}
#sidebar ul li a {
    padding: 10px 30px;
    font-size: .875rem;
    display: block;
    color: #25344f;
}
#sidebar ul li a:hover {
    color: #ffffff;
    background: rgb(253, 75, 75);
}
#sidebar ul li.active>a, a[aria-expanded="true"] {
    color: #fff!important;
    background: #fd4b4b;
}
#sidebar a[aria-expanded="true"] i {
    color: #ffffff!important;
}
#sidebar a[aria-expanded="true"]::after {
    color: #ffffff!important;
}
a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #7c858f;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #f1f1f1;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: calc(100% - 210px);
    padding: 0;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
#content.active {
    width: 100%;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #content {
        width: 100%;
    }
    #content.active {
        width: calc(100% - 250px);
    }
    #sidebarCollapse span {
        display: none;
    }
}

.btncollapse {
    background:transparent;
    border: none;
}
.btncollapse i {
    color: #212529;
    cursor: pointer;
    font-size: 14px;
}
.btncollapse i:hover{
    color: #c3c3c3;
    transition: all 0.3s;
}
.content-section {
    background-color : #f1f1f1;
    /* padding: 70px 50px; */
    /* padding: 70px 10px; */
}

.nav-item a i{
    color: #212529;
}
.nav-item a i:hover{
    color: #c3c3c3;
    transition: all 0.3s;
}
.rounded-pic {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    background:#fff;
}
.rounded-pic img {
    width: 100%;
}

li.nav-item {
    margin: 0 10px;
}
.sidebar-header img {
    width: 100%;
}

.sidebar-header .width_img_mov {
    width: 90%;
}
.list-unstyled i{
    font-size: 14px;
    margin-right: 10px;
}
#mostafaDropdown::after {
  display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: 0;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
}
.nav-item a {
    cursor: pointer;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 3%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0.125rem 0 0;
    font-size: 14px;
    color: #1b2235;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    width: 20%;
}
.main .panel {
    background: #ffffff;
    color: #25344f;
    margin-bottom: 20px;
    border-radius: 2px;
    width: 100%;
    float: left;
    border: 1px solid #dcdcdc;
    /* padding : 10px; */
}
.footerinfo p {
    font-size: 14px;
    color: #25344f;
    margin-left: 14px;
}
.main .panel h1 {
    font-size: 22px!important;
    color: #959595!important;
    text-transform: uppercase;
    font-family: 'gudea';
    letter-spacing: 7px;
    padding: 0!important;
    margin: 0!important;
    /* position: relative; */
    /* top: 10px; */
}
.breadcrumb-header {
    font-size: 20px;
    font-weight: 300;
    color: rgba(225,235,245,.8);
    margin-bottom: 24px;
}
.body-table {
  padding: 15px;
  border-radius: 2px;
    /* background: #24344d; */
    /* box-shadow: 0 1px 2px rgba(0,0,0,.15), 0 -1px 0 rgba(0,0,0,.02);  */
}
.body-table-responsive {
    font-size:13px;
    padding: 2px;
    /* background: #24344d; */
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0,0,0,.15), 0 -1px 0 rgba(0,0,0,.02); 
}
.body-table th {
    font-size: 14px;
    /* color: #fff; */
    font-weight: bold;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 1px solid var(--gray) !important;
    border-top: 1px solid var(--gray) !important;
    /* color: rgba(225,235,245,.8); */
}
.body-table tr {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    /* border-bottom: 1px solid #2e3f5b!important; */
    background: #24344d!important;
}
.body-table td {
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    /* border-bottom: 1px solid #2e3f5b!important; */
}
.table-bordered {
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 1px solid #2e3f5b!important;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #24344d!important;
}
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
    background-color: #24344d!important;
}
table.dataTable.display tbody tr.even>.sorting_1, table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
    background-color: #24344d!important;
}
table.dataTable thead th, table.dataTable thead td {
    padding: 10px 0!important;
    border-bottom: 1px solid #111;
}
table.dataTable tfoot th, table.dataTable tfoot td {
    padding: 10px 0px!important;
    border-top: 1px solid #111;
}
table.dataTable tbody th, table.dataTable tbody td {
    padding: 10px 0px!important;
}
.body-table label {
    font-size: 14px;
    color: #b9c3ce;
}
.body-table select {
    padding: 5px;
    border: 1px solid #ffffff;
    background: #ffffff;
    color: #24344d;
}
.body-table input[type="search"] {
    border-radius: 4px;
    border: 1px solid #324462;
    box-shadow: none!important;
    color: #b9c3ce;
    background: #24344d;
    padding: 4px 12px;
    font-size: 14px;
    position: relative;
    margin-right: 10px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{
    cursor: default;
    color: #b9c3ce!important;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #ffffff !important;
    border: 1px solid #2f4060;
    background-color: #1b2844;
    background: #1b2844!important;
    border-radius: 0;
    padding: 6px 15px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;
    color: #b9c3ce!important;
    border: 1px solid #2f4060;
    border-radius: 0px;
    padding: 6px 15px;
}
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
    color: #b9c3ce;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #fff !important;
    border: 1px solid #2f4060;
    background-color: #1b2844;
    background: #1b2844!important;
    border-radius: 0!important;
    padding: 6px 15px;
    transition: all 0.3s;
}
.btn_interactivo {
    width: 150px;
    text-align: center;
    margin-bottom: 40px;
}
.success_btn {
    border: 1px solid #2ec5d3;
    background: #2ec5d3;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
}
.success_btn a{
    display: block;
    padding: 10px;
}
.success_btn span{
    display: block;
    padding: 10px;
}
.success_btn:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #fd4b4b;
    transition: all 0.3s;
}
.panel p{
    font-size: 14px;
    color: #b9c3ce;
    margin:0;
}
.buscar_por {
    width: 20%;
    float: left;
}
.select_busqueda select {
    border-radius: 4px;
    border: 1px solid #324462;
    box-shadow: none!important;
    color: #b9c3ce;
    background: #24344d;
    padding: 4px 12px;
    font-size: 14px;
    width: 30%;
    position: relative;
    top: 5px;
    float: left;
    margin-right: 10px;
}
.select_busqueda input {
    border-radius: 4px;
    border: 1px solid #324462;
    box-shadow: none!important;
    color: #b9c3ce;
    background: #24344d;
    padding: 4px 12px;
    font-size: 14px;
    width: 65%;
    position: relative;
    top: 5px;
    float: left;
    margin-right: 10px;
}
.select_busqueda {
    width: 60%;
    float: left;
    margin-right: 10px;
}
.tabla_results {
    width: 100%;
    float: left;
    padding: 50px 0;
}
.tabla_results th{
    font-size: 14px;
    color: #fff;
    font-weight: 300!important;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 1px solid #2e3f5b!important;
    color: rgba(225,235,245,.8);
}
.tabla_results tr {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 1px solid #2e3f5b!important;
    background: #24344d!important;
}
.tabla_results td{
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 1px solid #2e3f5b!important;
}
.select_tienda .btn_interactivo {
    width: 150px;
    text-align: center;
    margin-bottom: 40px;
    float: left;
}
.buscar_por p{
    position: relative;
    top: 5px;
}
.select_tienda p{
    width: 100%;
    float: left;
}
.select_busqueda input::placeholder {
  color: #b9c3ce;
  opacity: 1; /* Firefox */
}
.select_busqueda input:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #b9c3ce;
}
.select_busqueda input::-ms-input-placeholder { /* Microsoft Edge */
 color: #b9c3ce;
}
.colnopadd {
    padding: 0!important;
}
.icon_text {
    text-align: center;
    width: 40px;
    height: 40px;
    padding: 7px;
    border-radius: 50%;
    float: none;
    margin: auto;
}
.icon_text p{
    font-size: 17px;
    color: #fff;
    margin: 0;
}
.activity .red {
    background: #ec5e69;
}
.activity .green {
    background: #5eec8c;
}
.activity .blue {
    background: #5e7aec;
}
.activity .purple {
    background: #9f7ce1;
}
.activity {
    width: 100%;
    float: left;
    margin: 20px 0;
}
.activity_title {
    padding: 0;
}
.panel canvas{

  width:100% !important;

}
.vermasnotificaciones {
    width: 100%;
    float: left;
}
.vermasnotificaciones .success_btn {
    border: 1px solid #2ec5d3;
    background: #2ec5d3;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    width: 190px;
    text-align: center;
}
.vermasnotificaciones .success_btn:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #fff;
    transition: all 0.3s;
}
.generar_reporte {
    width: 100%;
    float: left;
    margin: 30px 0;
}
.generar_reporte .success_btn {
    border: 1px solid #2ec5d3;
    background: #2ec5d3;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    width: 10%;
    text-align: center;
}
.generar_reporte .success_btn:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #fff;
    transition: all 0.3s;
}
.list-unstyled .active i {
    color: #ffffff;
}
.img-container {
    margin-left : 30%;
    margin-bottom : 25px;
    width:100px;
    height: 120px;
    overflow: hidden;
    float: none;
    /* margin: auto; */
}
.img-container img {
    width: 100%;
}

.botones_interactivos i {
    color: #b9c3ce;
    transition: all 0.3s;
    cursor: pointer;
}
.botones_interactivos i:hover {
    color: #ec5e69;
    transition: all 0.3s;
}

*[tooltip] {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
*[tooltip]:after, *[tooltip]:before {
  visibility: hidden;
  position: absolute;
  margin: 0;
  z-index: 98;
}
*[tooltip]:before {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.74) transparent;
  content: "";
}
*[tooltip]:after {
  background: rgba(0, 0, 0, 0.74);
  border-radius: 5px;
  color: #fff;
  content: attr(tooltip);
  padding: 5px 15px;
  font-size: 80%;
}
*[tooltip]:before, *[tooltip][tooltip-location="bottom"]:before {
  border-width: 0 6px 6px 6px;
  bottom: auto;
  top: 20px;
  left: 50%;
}
*[tooltip]:after, *[tooltip][tooltip-location="bottom"]:after {
  left: 0px;
  top: 26px;
}
*[tooltip][tooltip-location="top"]:before {
  border-width: 6px 6px 0px 6px;
  top: auto;
  bottom: 20px;
}
*[tooltip][tooltip-location="top"]:after {
  top: auto;
  bottom: 26px;
}
*[tooltip][tooltip-location="right"]:before {
  border-width: 6px 6px 0px 6px;
  top: 50%;
  left: 100%;
  transform: rotate(90deg);
}
*[tooltip][tooltip-location="right"]:after {
  top: -10%;
  left: calc(100% + 9px);
}
*[tooltip][tooltip-location="left"]:before {
  border-width: 6px 6px 0px 6px;
  top: 50%;
  right: 100%;
  left: auto;
  transform: rotate(-90deg);
}
*[tooltip][tooltip-location="left"]:after {
  top: -10%;
  left: auto;
  right: calc(100% + 9px);
}
*[tooltip]:hover:after, *[tooltip]:hover:before {
  visibility: visible;
}
*[tooltip][tooltip-animate]:before, *[tooltip][tooltip-animate]:after, *[tooltip][tooltip-animate][tooltip-location="bottom"]:before, *[tooltip][tooltip-animate][tooltip-location="bottom"]:after {
  margin: 10px 0px 0px 0px;
}
*[tooltip][tooltip-animate][tooltip-location="top"]:before, *[tooltip][tooltip-animate][tooltip-location="top"]:after {
  margin: 0px 0px 10px 0px;
}
*[tooltip][tooltip-animate][tooltip-location="right"]:before, *[tooltip][tooltip-animate][tooltip-location="right"]:after {
  margin: 0px 0px 0px 10px;
}
*[tooltip][tooltip-animate][tooltip-location="left"]:before, *[tooltip][tooltip-animate][tooltip-location="left"]:after {
  margin: 0px 10px 0px 0px;
}
*[tooltip][tooltip-animate]:after, *[tooltip][tooltip-animate]:before {
  transition: all 0.3s ease;
  opacity: 0;
}
*[tooltip][tooltip-animate]:hover:after, *[tooltip][tooltip-animate]:hover:before {
  margin: 0;
  opacity: 1;
}
*[tooltip][tooltip-size="small"]:after {
  width: 50px;
}
*[tooltip]:after, *[tooltip][tooltip-size="medium"]:after {
  width: 150px;
}
*[tooltip][tooltip-size="large"]:after {
  width: 250px;
}

.main_list_products{
    display: block;
}
.main_productos{
    display: none;
}
.colgrilla i{
    color: #b9c3ce;
    transition: all 0.3s;
}
.colgrilla {
    width: 18px;
    float: left;
    margin-right: 10px;
}
.colgrilla a{
    cursor: pointer;
}
.colgrilla .activar_vista i{
    color: #2ec5d3;
    transition: all 0.3s;
}
.colgrilla i:hover{
    color: #2ec5d3;
    transition: all 0.3s;
}
.add_producto {
    width: 150px;
    text-align: center;
    /* margin-bottom: 40px; */
    float: right;
}
.btn_add_producto {
    border: 1px solid var(--main);
    background: var(--main);
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    margin : 3px;
}
.btn_add_producto:hover {
    border: 1px solid var(--main);
    background: transparent;
    color: #fff;
    transition: all 0.3s;
}
.btn_add_producto span {
    display: block;
    padding: 6px;
    font-weight: bold;
    font-size:11px;
}
.modal-header {
    display: -ms-flexbox;
    display: block;
    -ms-flex-align: initial;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}
.modal-title {
    font-size: 16px;
    font-weight: normal;
    color: rgb(97, 97, 97);
}
.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 5px;
    outline: 0;
    margin-top:12%;
}
.custom-file-upload-hidden {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.custom-file-upload {
  display: block;
  width: auto;
  font-size: 16px;
  margin-top: 30px;
}
.custom-file-upload label {
  display: block;
  margin-bottom: 5px;
}

.file-upload-wrapper {
  position: relative;
  margin-bottom: 5px;
}
.file-upload-input {
    width: 50%;
    font-size: 12px;
    padding: 8px;
    color: #b5b5b5;
    border: none;
    background-color: #c0392b00;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    float: left;
    border-bottom: 1px solid #c8c8c875;
}
.file-upload-input:hover, .file-upload-input:focus {
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid #2ec5d37d;
}
.file-upload-button {
    cursor: pointer;
    display: inline-block;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    padding: 11px;
    border: 1px solid transparent;
    border-radius: 30px;
    margin-left: 0px;
    background-color: #2ec5d3;
    float: left;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    width: 50%;
}
.file-upload-button:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #2ec5d3;
    transition: all 0.3s;
}
.text-color{
    color: #ec5e69 !important;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1;
    color: #ec5e69;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    border : none;
    background : white;
}
.data_product input {
    width: 100%;
    float: right;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #2ec5d3;
    padding: 8px 20px;
    font-size: 14px;
    /* color: #bebebe; */
    margin-bottom: 10px;
    background: transparent;
}
.add_producto_confirm {
    width: 150px;
    text-align: center;
    margin-bottom: 40px;
    float: right;
}
.btn_add_producto_confirm {
    border: 1px solid #2ec5d3;
    background: #2ec5d3;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    padding: 10px;
}
.btn_add_producto_confirm:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #2ec5d3!important;
    transition: all 0.3s;
}
.btn_add_producto_confirm a {
    display: block;
}
.btn_add_producto_confirm span {
    display: block;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: initial;
}
.cancel_producto_confirm {
    width: 150px;
    text-align: center;
    margin-bottom: 40px;
}
.btn_cancelar {
    border: 1px solid #2ec5d3;
    background: #2ec5d3;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
}
.btn_cancelar:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #2ec5d3!important;
    transition: all 0.3s;
}
.btn_cancelar a {
    display: block;
    padding: 10px;
}
.delet_producto_confirm {
    width: 150px;
    text-align: center;
    margin-bottom: 40px;
    float: right;
}
.btn_delete_producto_confirm {
    border: 1px solid #ec5e69;
    background: #ec5e69;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
}
.btn_delete_producto_confirm:hover {
    border: 1px solid #ec5e69;
    background: transparent;
    color: #ec5e69!important;
    transition: all 0.3s;
}
.btn_delete_producto_confirm a {
    display: block;
    padding: 10px;
}
.modal-body .alert {
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    color: #f1c205;
    margin-bottom: 0;
}
.modal-body .text-alert {
    font-size: 14px;
    text-align: center;
    color: #9f9f9f;
}
.rounded-pic-lock {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    background: #fff;
    float: none;
    margin: auto;
}
.rounded-pic-lock img {
    width: 100%;
}
section.lockscreen {
    background: #f1f1f1;
    width: 100%;
    height: 100%;
}
.lockscreen h1 {
    font-size: 21px;
    color: #25344f;
    text-align: center;
    margin: 20px 0 6px;
}
.lockscreen p {
    font-size: 14px;
    color: #25344f;
    text-align: center;
    margin: 0;
}
.password-again {
    width: 340px;
    float: none;
    margin: auto;
    padding-top: 40px;
}
.add_password {
    width: 110px;
    text-align: center;
    margin-bottom: 40px;
    float: right;
}
.btn_add_password {
    border: 1px solid #fd4b4b;
    background: #fd4b4b;
    border-radius: 0 4px 4px 0;
    color: #fff;
    font-size: 13px;
}
.btn_add_password a {
    display: block;
    padding: 10px;
    cursor: pointer;
}
.btn_add_password:hover {
    border: 1px solid #d91212;
    background: #d91212;
    color: #fff;
    transition: all 0.3s;
}
.password-again input {
    border: 1px solid #fd4b4b;
    background: transparent;
    padding: 9px 11px;
    font-size: 14px;
    width: 230px;
    color: #fff;
}
.lockscreen{
    display: table;
    height:200px;
}
.pantallabloqueada {
    display: table-cell;
    vertical-align: middle;
}
#elementos i {
    color: #25344f;
}
.error_page{
    display: table;
    height:200px;
}
.page_notfound {
    padding-top: 15%;
    display: table-cell;
    vertical-align: middle !important;
}
section.error_page {
    background: #f1f1f1;
    width: 100%;
    height: 100%;
}
.error_page h1 {
    font-size: 150px;
    font-weight: 400;
    color: #fd4b4b;
    text-align: center;
    margin: 20px 0 6px;
}
.error_page p {
    font-size: 14px;
    color: #25344f;
    text-align: center;
    margin: 0;
}
.error_page h3 {
    font-size: 30px;
    color: #25344f;
    text-align: center;
    margin: 0;
}
.inicio_sesion {
    background-image: url(../Images/login.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.page_login {
    padding: 0 50px;
    height: 100%;
    width: 100%;
}
.form_login {
    height: 100%;
    width: 400px;
    padding: 30px 60px;
    float: right;
    background: #24344d;
}
.logo {
    position: relative;
    margin-top: 55px;
    width: 190px;
    float: none;
}
.logo img {
    width: 100%;
}
.form_login p {
    color: #fff;
    font-size: 14px;
    position: relative;
    margin-top: 40px;
}
.form_login input {
    border-radius: 4px;
    border: 1px solid #324462;
    box-shadow: none!important;
    color: #b9c3ce!important;
    background: #24344d;
    padding: 4px 12px;
    font-size: 14px;
    width: 100%;
    position: relative;
    top: 5px;
    float: left;
    margin-top: 10px;
}
button.login_btn {
    margin-top: 40px;
    width: 100%;
    background: #2ec5d3;
    border: 1px solid transparent;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    transition: all 0.3s;
}
button.login_btn:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #2ec5d3;
    transition: all 0.3s;
}
.forgot_password a{
    color: #fff;
    text-decoration: underline!important;
    transition: all 0.3s;
}
.forgot_password a:hover{
    color: #2ec5d3;
    transition: all 0.3s;
}
.form_login .footerinfo p {
    font-size: 14px;
    color: #fff;
    position: absolute;
    bottom: 0;
    margin: 30px 0;
}
.contenedor_restore {
    width: 400px;
    float: none;
    margin: auto;
}
.contenedor_restore .logo {
    position: relative;
    width: 190px;
    float: none;
    margin: auto;
}
.form_restore h1 {
    font-size: 16px;
    font-weight: 400;
    color: #25344f;
    text-align: center;
    margin: 20px 0 6px;
}
.form_restore {
    padding: 40px;
}
button.restore_btn {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #fd4b4b;
    background: #fd4b4b;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    transition: all 0.3s;
}
button.restore_btn:hover {
    border: 1px solid #d91212;
    background: #d91212;
    color: #fff;
    transition: all 0.3s;
}
.form_restore input {
    width: 100%;
    position: relative;
    margin-top: 10px;
    color: #25344f;
    margin-bottom: 0;
    background: transparent;
    padding: 5px 15px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #ec1c245c;
}
.data_product select {
    width: 100%;
    float: left;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #2ec5d3;
    padding: 8px 20px;
    font-size: 12px;
    color: #bebebe;
    margin-bottom: 10px;
}
.body-table td a {
    background: #2ec5d3;
    border:1px solid transparent;
    color: #fff;
    padding: 8px 20px;
    border-radius: 20px;
    transition: all 0.3s;
}
.body-table td a:hover {
    background: transparent;
    border: 1px solid #2ec5d3;
    color: #fff;
    transition: all 0.3s;
}
.ver_producto {
    background: var(--second);
    border: 1px solid transparent;
    color: #fff;
    padding: 4px 13px;
    border-radius: 10px;
    transition: all 0.3s;
}
.ver_producto:hover {
    background: transparent;
    border: 1px solid #2ec5d3;
    color: black;
    transition: all 0.3s;
}
.editar_producto {
    background: #5893df!important;
    border: 1px solid transparent;
    color: #fff;
    padding: 4px 13px;
    border-radius: 10px;
    transition: all 0.3s;
}
.editar_producto:hover {
    background: transparent!important;
    border: 1px solid #5893df!important;
    color: black;
    transition: all 0.3s;
}
.eliminar_producto {
    background: #ec5e69!important;
    border: 1px solid transparent;
    color: #fff;
    padding: 4px 13px;
    border-radius: 10px;
    transition: all 0.3s;
}
.eliminar_producto:hover {
    background: transparent!important;
    border: 1px solid #ec5e69!important;
    color: black;
    transition: all 0.3s;
}
.col-left {
    width: 50%;
    float: left;
}
.col-right {
    width: 50%;
    float: left;
}
.col-left p {
    font-size: 14px;
}
.col-right p {
    font-size: 14px;
}
.col-left select{
    width: 99%;
}
.data_product textarea {
    overflow: auto;
    resize: none;
    width: 100%;
    font-size: 13px;
    padding: 10px 20px;
    border: 1px solid #2ec5d3;
    border-radius: 4px;
    margin-bottom: 10px;
}
.ver_lista_productos{
    width: 100%;
    float: left;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #2ec5d3;
    padding: 8px 20px;
    font-size: 12px;
    color: #bebebe;
    margin-bottom: 10px;
    background: transparent;
}
.notification_body {
    padding: 10px 0;
    border-bottom: 1px solid #2e3f5b;
}
.notification_body span {
    background: #ec5e69;
    padding: 4px 10px;
    border-radius: 30px;
    color: #fff;
}
.panel_time_notification{
    text-align: right;
}
.panel_time_notification a p:hover{
    color: #ec5e69;
    transition: all 0.3s;
}
.data_notification h1{
    font-size: 16px;
    font-weight: normal;
    color: rgb(97, 97, 97);
}

.data_notification p{
    font-size: 14px;
    font-weight: normal;
    color: rgb(97, 97, 97);
}
.body_drop_notification h1{
    font-size: 16px;
    font-weight: normal;
    color: rgb(97, 97, 97);
}
.body_drop_notification p{
    font-size: 14px;
    font-weight: normal;
    color: rgb(97, 97, 97);
}
.body_drop_notification span {
    background: #ec5e69;
    padding: 4px 10px;
    border-radius: 30px;
    color: #fff;
}
.body_drop_notification {
    border-bottom: 1px solid #2e3f5b24;
    height: 90px;
    overflow: hidden;
}
.dropdown_noti {
    height: 280px;
    padding: 20px 4px;
    overflow: hidden;
    overflow-y: scroll;
}
.body_drop_message h1{
    font-size: 16px;
    font-weight: normal;
    color: rgb(97, 97, 97);
}
.body_drop_message p{
    font-size: 14px;
    font-weight: normal;
    color: rgb(97, 97, 97);
}
.body_drop_message {
    height: 90px;
    overflow: hidden;
    border-bottom: 1px solid #2e3f5b24;
}
.dropdown_noti {
    height: 280px;
    padding: 20px 4px;
    overflow: hidden;
    overflow-y: scroll;
}
.nav-item.active a i {
    color: #ec5e69;
}
.body_drop_message:after{
    width: 100%;
}
.body_drop_message:before{
    display: none;
}
.panel_mensajes a {
    display: block;
    height: 85px;
    overflow: hidden;
    transition: all 0.3s;
    padding: 6px;
}
.panel_mensajes a:hover {
    background: #1a223326;
    transition: all 0.3s;
}
.contact_body {
    height: 100%;
    border-bottom: 1px solid #2e3f5b;
}
.panel_mensajes {
    height: 710px;
    overflow: hidden;
    overflow-y: scroll;
}
.panel_mensajes::-webkit-scrollbar {
  background: transparent;
}
.panel_mensajes::-webkit-scrollbar:vertical {
  width: 1em;
}
.panel_mensajes::-webkit-scrollbar:horizontal {
  height: 0;
}
.panel_mensajes::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}
.panel_mensajes::-webkit-scrollbar {
  background: transparent;
}
.panel_mensajes::-webkit-scrollbar:vertical {
  width: 1em;
}
.panel_mensajes::-webkit-scrollbar:horizontal {
  height: 0;
}
.panel_mensajes::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}

.dropdown_noti::-webkit-scrollbar {
  background: transparent;
}
.dropdown_noti::-webkit-scrollbar:vertical {
  width: 1em;
}
.dropdown_noti::-webkit-scrollbar:horizontal {
  height: 0;
}
.dropdown_noti::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}
.dropdown_noti::-webkit-scrollbar {
  background: transparent;
}
.dropdown_noti::-webkit-scrollbar:vertical {
  width: 1em;
}
.dropdown_noti::-webkit-scrollbar:horizontal {
  height: 0;
}
.dropdown_noti::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}
.chatleft{
    width: 100%;
    float: left;
}
.chatright{
    width: 100%;
    float: left;
}
.panel_conversation {
    height: 710px;
}
.conversation_body {
    height: 480px;
    overflow: hidden;
    overflow-y: scroll;
}
.conversation_body::-webkit-scrollbar {
  background: transparent;
}
.conversation_body::-webkit-scrollbar:vertical {
  width: 1em;
}
.conversation_body::-webkit-scrollbar:horizontal {
  height: 0;
}
.conversation_body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}
.conversation_body::-webkit-scrollbar {
  background: transparent;
}
.conversation_body::-webkit-scrollbar:vertical {
  width: 1em;
}
.conversation_body::-webkit-scrollbar:horizontal {
  height: 0;
}
.conversation_body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}
.burbuja.left {
    width: 50%;
    background: #00000026;
    padding: 20px;
    border-radius: 50px;
    float: left;
}
.burbuja p{
    font-size: 11px;
    color: #b9c3ce8c;
    margin: 0;
}
.burbuja h1 {
    font-size: 14px;
    color: #b9c3ce;
}
.burbuja.right {
    width: 50%;
    background: #00000026;
    padding: 20px;
    border-radius: 50px;
    float: right;
}
.box_message {
    width: 100%;
    padding: 10px 0;
}
.box_message textarea {
    overflow: auto;
    resize: none;
    width: 100%;
    border: 1px solid #2e3f5b;
    background: transparent;
    color: #fff;
    font-size: 14px;
    padding: 10px;
    height: 130px;
}
.enviar_mensaje {
    width: 100%;
    float: left;
    margin: 45px 0;
}
.enviar_mensaje .send_btn {
    border: 1px solid #2ec5d3;
    background: #2ec5d3;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    width: 100%;
    text-align: center;
}
.send_btn a {
    display: block;
    padding: 10px;
}
.enviar_mensaje .send_btn:hover {
    border: 1px solid #2ec5d3;
    background: transparent;
    color: #fff;
    transition: all 0.3s;
}
.panel_mensajes .active {
    background: #1a223352;
    transition: all 0.3s;
}
.panel .col-md-6 input  {
    width: 100%;
    float: left;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #2ec5d3;
    padding: 8px 20px;
    font-size: 12px;
    color: #bebebe;
    margin-bottom: 10px;
    background: transparent;
}
.inputs_config {
    width: 100%;
    float: left;
    margin: 40px 0;
}
.inputs_config label {
    width: 100%;
    float: left;
    color: #b4bcca;
}
.boton_guardar_info {
    padding : 10px;
    width: 150px;
    float: right;
    text-align: center;
}
#elementos:hover i {
    color: #fff;
    transition: all 0.3s;
}
.dropdown-toggle:hover::after {
    transition: all 0.3s;
    color: #ffffff;
}
li.cabecera_li {
    color: #cbcbcb;
    padding: 12px 30px 10px;
    font-size: 12px;
    font-weight: bold;
}
.content-section .main .panel .col-md-6 i {
    font-size: 35px;
    color: #fd4b4b;
    width: 50%;
    float: left;
    text-align: center;
    position: relative;
    top: 8px;
}
.panel .content_info p {
    font-size: 34px;
    color: #25344f;
    /* margin: 0; */
    /* text-align: right; */
    /* width: 50%; */
    /* float: left; */
    /* position: relative; */
    /* top: 0px; */
}
.content-section .main h1 {
    font-size: 27px;
    padding: 0px 10px;
    color: #949494;
}
.main .panel a {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s;
}
.main .panel a:hover {
    transition: all 0.3s;
    -webkit-box-shadow: 0px 0px 28px -5px rgba(10,10,10,0.24);
    -moz-box-shadow: 0px 0px 28px -5px rgba(10,10,10,0.24);
    box-shadow: 0px 0px 28px -5px rgba(10,10,10,0.24);
}
.body_tabs {
    padding: 45px 20px;
}
.header_historial {
    display : flex;
    flex-direction : row;
    align-items: center;
    background: #d7d7d7;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #dcdcdc;
}
.header_historial p{
    color: #fff;
    letter-spacing: 5px;
}
.content_historial {
    background: #fff;
    border: 1px solid #dcdcdc;
    padding: 20px;
}
.content_historial p {
    padding: 0;
    margin: 0 0 20px 0;
    line-height: 1;
    font-size: 14px;
    color: #25344f;
}
.cabeceras {
  font-weight:bold;
    background: #f1f1f1;
    padding: 10px;
}
.cabeceras_row{
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}
.col20 {
    width: 20%;
    float: left;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}
.col25{
    width: 25%;
    float: left;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.cabeceras .col20 p{
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: #25344f;
}
.data_candidato p{
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: #25344f;
}
.data_candidato {
  /* padding:2px; */
  /* border-top: 1px solid #f1f1f1; */
  border-bottom: 1px solid var(--bs-border-color);
}
.data_candidato a:hover {
    color: #fd4b4b;
    transition: all 0.3s;
}
.cabecera_recently {
    background: #eceff1;
    padding: 10px;
    border: 1px solid #dcdcdc;
    display : flex;
    flex-direction : row;
    align-items: center
}
.cabecera_recently i {
    color: #607d8b;
    float: left;
    margin-right: 20px;
}
.cabecera_recently p{
    margin: 0!important;
    padding: 0;
    line-height: 1;
    color: #607d8b;
    letter-spacing: 5px;
}
.cabeceras_actividad{
    background: #f1f1f1;
    padding: 10px;
}
.cabeceras_actividad p{
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: #25344f;
}
.body_history {
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
}
.footer_history {
    text-align: right;
}
.footer_history a{
    font-size: 14px;
    color: #fd4b4b;
    transition: all 0.3s;
}
.footer_history a:hover{
    color: #981111;
    transition: all 0.3s;
}
.filtros_history .col-md-4 {
    padding-left: 0!important;
}
.filtros_history .col-md-4 input {
    width: 100%;
    border: 1px solid whitesmoke;
    font-size: 14px;
    padding: 1px 10px;
}
.filtros_history .col-md-4 label {
    padding : 10px;
    padding-left : 0px;
    font-size: 14px;
    color: #25344f;
}
.filtros_history .col-md-4 select{
    font-size: 14px;
    color: #798082;
    border: 1px solid #eceff1;
    padding: 1px 10px;
    width: 100%;
}
.filtros_history {
    margin-bottom: 30px;
}
input#custom-input-date {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
}
.label-error {
  height: 13px;
  margin-top: 4px;
  font-size: 10px;
  color: #DC3545;
}
input#custom-input-date:focus {
  border: 1px solid #007BFF;
}
input#custom-input-date,
label {
  display: block;
  outline: none;
}
.buscar_filtros_history {
    border: 1px solid #fd4b4b;
    color: #fff;
    background: #fd4b4b;
    padding: 2px;
    width: 100%;
    font-size: 14px;
    position: relative;
    /* top: 27px; */
    cursor: pointer;
    transition: all 0.3s;
}
.buscar_filtros_history:hover {
    border: 1px solid #fd4b4b;
    color: #fd4b4b;
    background: #fff;
    transition: all 0.3s;
}
.footerinfo a {
    font-size: 14px;
    color: #ababab;
    margin-right: 10px;
    float: left;
    transition: all 0.3s;
}
.footerinfo a:hover {
    color: #fd4b4b;
    transition: all 0.3s;
}
.active .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #ffffff;
}
.footerinfo {
    padding: 0;
    width: 90%;
    bottom: 0;
    margin: 0;
}
.tabs_pruebas .test_tab.active {
    background: #fd4b4b;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
}
.tabs_pruebas .test_tab.active p{
    margin: 0;
    letter-spacing: 9px;
    color: #fff;
}
.tabs_pruebas .test_tab.active:hover {
    background: #f81919;
    transition: all 0.3s;
}
.tabs_pruebas .test_tab {
    background: #d0d0d0;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
}
.tabs_pruebas .test_tab p{
    margin: 0!important;
    letter-spacing: 9px;
    color: #fff!important;
}
.tabs_pruebas .test_tab:hover {
    background: #f81919;
    transition: all 0.3s;
}
.tabs_pruebas {
    margin-bottom: 40px;
}
.todas_las_pruebas_body{
    display: block;
    margin-bottom: 80px;
}
.comportamiento_body {
    display: none;
    margin-bottom: 80px;
}
.personalidad_body {
    display: none;
    margin-bottom: 80px;
}
.inteligencia_body {
    display: none;
    margin-bottom: 80px;
}
.modern-Checkbox [type="checkbox"] {
  display: none;
}
.modern-Checkbox [type="checkbox"]:not(:checked) + label, .modern-Checkbox [type="checkbox"] :not(:checked) + label:hover {
    background-color: white;
    color: #363636;
    border: 1px solid #d8d8d8;
    width: 100%;
    border-radius: 0;
    font-size: 14px;
    font-family: 'gudea';
    padding: 35px;
    letter-spacing: 6px;
}
.modern-Checkbox [type="checkbox"]:checked + label:before {
    transform: rotate(45deg);
    color: #fff;
}

.modern-Checkbox label {
    color: #fff;
    background-color: #fd4b4b78;
    border-color: #fd4b4b;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 35px;
    font-size: 14px;
    border-radius: 0;
    transition: all 144ms ease-in-out;
    width: 100%;
    letter-spacing: 6px;
}
.modern-Checkbox label:hover {
  transform: scale(0.985);
}
.modern-Checkbox label:before {
    float: left;
    margin-top: -0.75rem;
    margin-bottom: -1rem;
    margin-right: 0.4rem;
    margin-left: -0.2rem;
    font-weight: 100;
    font-size: 2rem;
    transition: transform 200ms ease-in-out;
    content: "+";
    position: relative;
    top: -2px;
    color: #fd4b4b;
}

.content-section .main p {
    color: #878787;
    /* padding: 0 15px; */
    /* margin-bottom: 40px; */
}
.datos_candidato {
    margin-bottom: 40px;
}
.botones_preguntas label {
    font-size: 14px;
    color: #878787;
    padding-left: 15px;
}
.datos_candidato select {
    border: 1px solid #d0d0d0;
    width: 100%;
    padding: 10px;
    color: #878787;
    cursor: pointer;
}
.datos_candidato input {
    border: 1px solid #d0d0d0;
    width: 100%;
    padding: 9px;
    color: #878787;
}
.botones_interactivos_asignar {
    text-align: right;
    margin-bottom: 50px;
}
.botones_interactivos_asignar span{
    margin-right:25px;
    font-size: 14px;
    color: #fd4b4b;
    transition: all 0.3s;
}
.botones_interactivos_asignar span:hover {
    color: #981111;
    transition: all 0.3s;
}
.botones_interactivos_asignar button {
    border: 1px solid #fd4b4b;
    color: #fff;
    background: #fd4b4b;
    padding: 10px 55px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
}
.botones_interactivos_asignar button:hover {
    border: 1px solid #fd4b4b;
    color: #fd4b4b;
    background: transparent;
    transition: all 0.3s;
}
.prueba_item{
    padding-top : 15px;
}
.body_prueba_list .modern-Checkbox label {
    color: #363636;
    background-color: #ffffff;
    border-color: #fd4b4b;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 35px;
    font-size: 14px;
    border-radius: 0;
    transition: all 144ms ease-in-out;
    width: 100%;
    letter-spacing: 6px;
}
.body_prueba_list .modern-Checkbox label:before{
    display: none;
}
.content-section .main_pruebas_list h1 {
    font-size: 27px;
    padding: 0px 15px 25px;
    color: #949494;
}
.crear_nueva_prueba {
    /* padding: 10px 0px 10px; */
    text-align: right;
}
.btn_crear_prueba {
    border: 1px solid #fd4b4b;
    color: #fff;
    background: #fd4b4b;
    padding: 10px 20px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
}
.btn_crear_prueba:focus {
    color: #fff;
    transition: all 0.3s;
}
.btn_crear_prueba:hover {
    border: 1px solid #fd4b4b;
    color: #fd4b4b;
    background: transparent;
    transition: all 0.3s;
}
.btn_agregar_pregunta {
    border: 1px solid #fd4b4b;
    color: #fff;
    background: #fd4b4b;
    padding: 10px 55px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
}
.btn_agregar_pregunta:focus {
    color: #fff;
    transition: all 0.3s;
}
.btn_agregar_pregunta:hover {
    border: 1px solid #fd4b4b;
    color: #fd4b4b;
    background: transparent;
    transition: all 0.3s;
}
.body_prueba_nueva {
    padding: 40px 50px;
    background: #fff;
    margin-bottom: 40px;
    margin: 0 15px 40px 15px;
}
.body_prueba_nueva .contenedor_pruebas h1 {
    font-size: 18px;
    padding: 0;
    color: #949494;
    margin: 0;
}
.titulo_pregunta {
    padding: 10px 0;
}
.body_prueba_nueva .contenedor_pruebas .numero_pregunta p {
    color: #fd4b4b;
    padding: 0 15px;
    margin-bottom: 0;
    font-weight: bold;
}
.body_prueba_nueva .contenedor_pruebas .titulo_pregunta span {
    color: #878787;
    padding: 0 15px 0 0;
    margin-bottom: 0;
}
.respuesta_pregunta {
    padding: 10px 0px;
}
.respuesta_pregunta input {
    width: 100%;
    padding: 5px;
    border: 1px solid #d0d0d0;
    transition: all 0.3s;
}
.respuesta_pregunta input:focus {
    border: 1px solid #fd4b4b;
    transition: all 0.3s;
}
.body_preg_indi {
    margin-bottom: 20px;
}
.respuesta_pregunta .radio input[type=radio] {
  display: none;
}
.radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  font-size: 14px;
}

.radio label:before {
    content: "";
    display: inline-block;
    width: 19px;
    height: 19px;
    background-color: #fff;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 1px;
    border: 1px solid #d0d0d0;
}
.radio label:before {
  border-radius: 9px;
}
.radio input[type=radio]:checked + label:before {
    content: "\2022";
    position: absolute;
    color: #fd4b4b;
    font-size: 29px;
    border: 3px solid #fd4b4b;
    border-radius: 50%;
    padding: 0px;
    line-height: 8px;
    width: 19px;
    height: 19px;
    box-sizing: border-box;
    box-shadow: none;
}
input.input_pregunta {
    border-bottom: 1px solid #d0d0d0;
    box-shadow: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 100%;
    transition: all 0.3s;
}
input.input_pregunta:focus {
    border-bottom: 1px solid #fd4b4b;
    transition: all 0.3s;
}
.historial_candidatos {
    margin-bottom: 30px;
}
.radio form {
    margin: 0;
}
.button_subir_foto_pregunta input[type="file"] { 
    display: none; 
}
.button_subir_foto_pregunta label{
    background: #fd4b4b;
    border: none;
    border-radius: 0;
    font-size: 14px;
    transition: all 0.3s;
}
.button_subir_foto_pregunta label:hover{
    background: #ff1a1a;
    transition: all 0.3s;
}
.img_relaciones {
    margin: 30px 0;
    width: 11%;
    margin-right: 40px;
    transition: all 0.3s;
}
.img_relaciones img {
    width: 100%;
}
.button_subir_foto_pregunta {
    margin: 20px 0;
}
.input_hidden {
    position: absolute;
    left: -9999px;
}
.selected {
    background-color: #fd4b4b;
}
#sites label {
    display: inline-block;
    cursor: pointer;
    width: 90px;
    height: 90px;
}
#sites label:hover {
    border: 1px solid #fd4b4b;
}
#sites label img {
    padding: 3px;
    width: 100%;
}
.form-group-guardar-prueba input {
    background: #fd4b4b;
    font-size: 14px;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
}
.form-group-guardar-prueba input:hover {
    background: #ff1a1a;
    transition: all 0.3s;
}
.form-group-guardar-prueba {
    text-align: right;
}
.header_mobile {
    display: none;
}
.dl-menuwrapper {
    width: 100%;
    max-width: 100%;
    float: left;
    position: relative;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    z-index: 9;
    -webkit-perspective-origin: 50% 200%;
    -moz-perspective-origin: 50% 200%;
    perspective-origin: 50% 200%;
}

.dl-menuwrapper:first-child {
    margin-right: 0px;
}
.dl-menuwrapper button {
    background: #fd4b4b;
    border: none;
    width: 50px;
    height: 45px;
    color: #fff;
    text-indent: 0;
    padding: 11px;
    position: relative;
    cursor: pointer;
    outline: none;
}
.dl-menuwrapper button:hover, .dl-menuwrapper button.dl-active, .dl-menuwrapper ul {
    background: #fff;
    transition: all 0.3s;
}
/*.dl-menuwrapper button:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fff;
    top: 10px;
    left: 16%;
    box-shadow:
        0 10px 0 #fff,
        0 20px 0 #fff;
}
*/
.dl-menuwrapper ul {
    padding: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.dl-menuwrapper li {
    position: relative;
}

.dl-menuwrapper li a {
    display: block;
    position: relative;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #fff;
    outline: none;
    font-family: 'montserrat';
}

.no-touch .dl-menuwrapper li a:hover {
    background: rgba(253, 75, 75, 0.1);
}

.dl-menuwrapper li.dl-back > a {
    padding-left: 30px;
    background: rgba(0,0,0,0.1);
}

.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li > a:not(:only-child):after {
    position: absolute;
    top: 0;
    line-height: 50px;
    font-family: 'gotham';
    speak: none;
    -webkit-font-smoothing: antialiased;
}

.dl-menuwrapper li.dl-back:after {
    left: 10px;
    color: rgba(212,204,198,0.3);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
    right: 10px;
    color: rgba(0,0,0,0.15);
}

.dl-menuwrapper .dl-menu {
    margin: 5px 0 0 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
    display: none;
}
i.fa.fa-angle-right {
    position: relative;
    left: 30px;
}
i.fa.fa-angle-left {
    position: relative;
    padding-right: 20px;
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
    display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
    display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0;
    margin: 0;
}

/* Animation classes for moving out and in */

.dl-menu.dl-animate-out-1 {
    -webkit-animation: MenuAnimOut1 0.4s;
    -moz-animation: MenuAnimOut1 0.4s;
    animation: MenuAnimOut1 0.4s;
}

.dl-menu.dl-animate-out-2 {
    -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
    -moz-animation: MenuAnimOut2 0.3s ease-in-out;
    animation: MenuAnimOut2 0.3s ease-in-out;
}

.dl-menu.dl-animate-out-3 {
    -webkit-animation: MenuAnimOut3 0.4s ease;
    -moz-animation: MenuAnimOut3 0.4s ease;
    animation: MenuAnimOut3 0.4s ease;
}

.dl-menu.dl-animate-out-4 {
    -webkit-animation: MenuAnimOut4 0.4s ease;
    -moz-animation: MenuAnimOut4 0.4s ease;
    animation: MenuAnimOut4 0.4s ease;
}

.dl-menu.dl-animate-out-5 {
    -webkit-animation: MenuAnimOut5 0.4s ease;
    -moz-animation: MenuAnimOut5 0.4s ease;
    animation: MenuAnimOut5 0.4s ease;
}

@-webkit-keyframes MenuAnimOut1 {
    50% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
        -webkit-transform: translateZ(-372.5px) rotateY(15deg);
        opacity: .5;
    }
    100% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut2 {
    100% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut3 {
    100% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut4 {
    100% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-webkit-keyframes MenuAnimOut5 {
    100% {
        -webkit-transform: translateY(40%);
        opacity: 0;
    }
}

@-moz-keyframes MenuAnimOut1 {
    50% {
        -moz-transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
        -moz-transform: translateZ(-372.5px) rotateY(15deg);
        opacity: .5;
    }
    100% {
        -moz-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
}

@-moz-keyframes MenuAnimOut2 {
    100% {
        -moz-transform: translateX(-100%);
        opacity: 0;
    }
}

@-moz-keyframes MenuAnimOut3 {
    100% {
        -moz-transform: translateZ(300px);
        opacity: 0;
    }
}

@-moz-keyframes MenuAnimOut4 {
    100% {
        -moz-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-moz-keyframes MenuAnimOut5 {
    100% {
        -moz-transform: translateY(40%);
        opacity: 0;
    }
}

@keyframes MenuAnimOut1 {
    50% {
        transform: translateZ(-250px) rotateY(30deg);
    }
    75% {
        transform: translateZ(-372.5px) rotateY(15deg);
        opacity: .5;
    }
    100% {
        transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
}

@keyframes MenuAnimOut2 {
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes MenuAnimOut3 {
    100% {
        transform: translateZ(300px);
        opacity: 0;
    }
}

@keyframes MenuAnimOut4 {
    100% {
        transform: translateZ(-300px);
        opacity: 0;
    }
}

@keyframes MenuAnimOut5 {
    100% {
        transform: translateY(40%);
        opacity: 0;
    }
}

.dl-menu.dl-animate-in-1 {
    -webkit-animation: MenuAnimIn1 0.3s;
    -moz-animation: MenuAnimIn1 0.3s;
    animation: MenuAnimIn1 0.3s;
}

.dl-menu.dl-animate-in-2 {
    -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
    -moz-animation: MenuAnimIn2 0.3s ease-in-out;
    animation: MenuAnimIn2 0.3s ease-in-out;
}

.dl-menu.dl-animate-in-3 {
    -webkit-animation: MenuAnimIn3 0.4s ease;
    -moz-animation: MenuAnimIn3 0.4s ease;
    animation: MenuAnimIn3 0.4s ease;
}

.dl-menu.dl-animate-in-4 {
    -webkit-animation: MenuAnimIn4 0.4s ease;
    -moz-animation: MenuAnimIn4 0.4s ease;
    animation: MenuAnimIn4 0.4s ease;
}

.dl-menu.dl-animate-in-5 {
    -webkit-animation: MenuAnimIn5 0.4s ease;
    -moz-animation: MenuAnimIn5 0.4s ease;
    animation: MenuAnimIn5 0.4s ease;
}

@-webkit-keyframes MenuAnimIn1 {
    0% {
        -webkit-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
    20% {
        -webkit-transform: translateZ(-250px) rotateY(30deg);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: translateZ(0px) rotateY(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn2 {
    0% {
        -webkit-transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes MenuAnimIn5 {
    0% {
        -webkit-transform: translateY(40%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes MenuAnimIn1 {
    0% {
        -moz-transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
    20% {
        -moz-transform: translateZ(-250px) rotateY(30deg);
        opacity: 0.5;
    }
    100% {
        -moz-transform: translateZ(0px) rotateY(0deg);
        opacity: 1;
    }
}

@-moz-keyframes MenuAnimIn2 {
    0% {
        -moz-transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        -moz-transform: translateX(0px);
        opacity: 1;
    }
}

@-moz-keyframes MenuAnimIn3 {
    0% {
        -moz-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateZ(0px);
        opacity: 1;
    }
}

@-moz-keyframes MenuAnimIn4 {
    0% {
        -moz-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateZ(0px);
        opacity: 1;
    }
}

@-moz-keyframes MenuAnimIn5 {
    0% {
        -moz-transform: translateY(40%);
        opacity: 0;
    }
    100% {
        -moz-transform: translateY(0);
        opacity: 1;
    }
}

@keyframes MenuAnimIn1 {
    0% {
        transform: translateZ(-500px) rotateY(0deg);
        opacity: 0;
    }
    20% {
        transform: translateZ(-250px) rotateY(30deg);
        opacity: 0.5;
    }
    100% {
        transform: translateZ(0px) rotateY(0deg);
        opacity: 1;
    }
}

@keyframes MenuAnimIn2 {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes MenuAnimIn3 {
    0% {
        transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes MenuAnimIn4 {
    0% {
        transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes MenuAnimIn5 {
    0% {
        transform: translateY(40%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
    -webkit-animation: SubMenuAnimIn1 0.4s ease;
    -moz-animation: SubMenuAnimIn1 0.4s ease;
    animation: SubMenuAnimIn1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
    -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
    -moz-animation: SubMenuAnimIn2 0.3s ease-in-out;
    animation: SubMenuAnimIn2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
    -webkit-animation: SubMenuAnimIn3 0.4s ease;
    -moz-animation: SubMenuAnimIn3 0.4s ease;
    animation: SubMenuAnimIn3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
    -webkit-animation: SubMenuAnimIn4 0.4s ease;
    -moz-animation: SubMenuAnimIn4 0.4s ease;
    animation: SubMenuAnimIn4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
    -webkit-animation: SubMenuAnimIn5 0.4s ease;
    -moz-animation: SubMenuAnimIn5 0.4s ease;
    animation: SubMenuAnimIn5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimIn1 {
    0% {
        -webkit-transform: translateX(50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn2 {
    0% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn3 {
    0% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn4 {
    0% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SubMenuAnimIn5 {
    0% {
        -webkit-transform: translateZ(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
}

@-moz-keyframes SubMenuAnimIn1 {
    0% {
        -moz-transform: translateX(50%);
        opacity: 0;
    }
    100% {
        -moz-transform: translateX(0px);
        opacity: 1;
    }
}

@-moz-keyframes SubMenuAnimIn2 {
    0% {
        -moz-transform: translateX(100%);
        opacity: 0;
    }
    100% {
        -moz-transform: translateX(0px);
        opacity: 1;
    }
}

@-moz-keyframes SubMenuAnimIn3 {
    0% {
        -moz-transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateZ(0px);
        opacity: 1;
    }
}

@-moz-keyframes SubMenuAnimIn4 {
    0% {
        -moz-transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateZ(0px);
        opacity: 1;
    }
}

@-moz-keyframes SubMenuAnimIn5 {
    0% {
        -moz-transform: translateZ(-200px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn1 {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn2 {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn3 {
    0% {
        transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn4 {
    0% {
        transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes SubMenuAnimIn5 {
    0% {
        transform: translateZ(-200px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
    -webkit-animation: SubMenuAnimOut1 0.4s ease;
    -moz-animation: SubMenuAnimOut1 0.4s ease;
    animation: SubMenuAnimOut1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
    -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
    -moz-animation: SubMenuAnimOut2 0.3s ease-in-out;
    animation: SubMenuAnimOut2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
    -webkit-animation: SubMenuAnimOut3 0.4s ease;
    -moz-animation: SubMenuAnimOut3 0.4s ease;
    animation: SubMenuAnimOut3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
    -webkit-animation: SubMenuAnimOut4 0.4s ease;
    -moz-animation: SubMenuAnimOut4 0.4s ease;
    animation: SubMenuAnimOut4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
    -webkit-animation: SubMenuAnimOut5 0.4s ease;
    -moz-animation: SubMenuAnimOut5 0.4s ease;
    animation: SubMenuAnimOut5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimOut1 {
    0% {
        -webkit-transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50%);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut2 {
    0% {
        -webkit-transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100%);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut3 {
    0% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut4 {
    0% {
        -webkit-transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(300px);
        opacity: 0;
    }
}

@-webkit-keyframes SubMenuAnimOut5 {
    0% {
        -webkit-transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(-200px);
        opacity: 0;
    }
}

@-moz-keyframes SubMenuAnimOut1 {
    0% {
        -moz-transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -moz-transform: translateX(50%);
        opacity: 0;
    }
}

@-moz-keyframes SubMenuAnimOut2 {
    0% {
        -moz-transform: translateX(0%);
        opacity: 1;
    }
    100% {
        -moz-transform: translateX(100%);
        opacity: 0;
    }
}

@-moz-keyframes SubMenuAnimOut3 {
    0% {
        -moz-transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -moz-transform: translateZ(-300px);
        opacity: 0;
    }
}

@-moz-keyframes SubMenuAnimOut4 {
    0% {
        -moz-transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        -moz-transform: translateZ(300px);
        opacity: 0;
    }
}

@-moz-keyframes SubMenuAnimOut5 {
    0% {
        -moz-transform: translateZ(0);
        opacity: 1;
    }
    100% {
        -moz-transform: translateZ(-200px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut1 {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(50%);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut2 {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut3 {
    0% {
        transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        transform: translateZ(-300px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut4 {
    0% {
        transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        transform: translateZ(300px);
        opacity: 0;
    }
}

@keyframes SubMenuAnimOut5 {
    0% {
        transform: translateZ(0);
        opacity: 1;
    }
    100% {
        transform: translateZ(-200px);
        opacity: 0;
    }
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
    display: block;
}

.no-js .dl-menuwrapper li.dl-back {
    display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
    background: rgba(0,0,0,0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
    content: '';
}

/* Colors for demos */

/* Demo 1 */
.demo-1 .dl-menuwrapper button {
    background: #26ae62;
}

.demo-1 .dl-menuwrapper button:hover,
.demo-1 .dl-menuwrapper button.dl-active,
.demo-1 .dl-menuwrapper ul {
    background: #108242;
}

/* Demo 2 */
.demo-2 .dl-menuwrapper button {
    background: #e86814;
}

.demo-2 .dl-menuwrapper button:hover,
.demo-2 .dl-menuwrapper button.dl-active,
.demo-2 .dl-menuwrapper ul {
    background: #D35400;
}

/* Demo 3 */
.demo-3 .dl-menuwrapper button {
    background: #08cbc4;
}

.demo-3 .dl-menuwrapper button:hover,
.demo-3 .dl-menuwrapper button.dl-active,
.demo-3 .dl-menuwrapper ul {
    background: #00b4ae;
}

/* Demo 4 */
.demo-4 .dl-menuwrapper button {
    background: #90b912;
}

.demo-4 .dl-menuwrapper button:hover,
.demo-4 .dl-menuwrapper button.dl-active,
.demo-4 .dl-menuwrapper ul {
    background: #79a002;
}

/* Demo 5 */
.demo-5 .dl-menuwrapper button {
    background: #744783;
}

.demo-5 .dl-menuwrapper button:hover,
.demo-5 .dl-menuwrapper button.dl-active,
.demo-5 .dl-menuwrapper ul {
    background: #643771;
}
.filtros_resp{
    display: none;
}
.dl-menuwrapper button:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fff;
    top: 10px;
    left: 16%;
    box-shadow: 0 10px 0 #fff, 0 20px 0 #fff;
}
.dl-menuwrapper button:hover:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fd4b4b;
    top: 10px;
    left: 16%;
    box-shadow: 0 10px 0 #fd4b4b, 0 20px 0 #fd4b4b;
    transition: all 0.3s;
}
.demo-1 .dl-menuwrapper button {
    background: #26ae62;
}
.logo-responsive {
    display: none;
}
@media (min-width: 320px) and (max-width: 484px){
.navbar {
    display: none;
}
.logo-responsive {
    width: 60%;
    float: right;
    position: relative;
    top: -55px;
    display: block;
}
.logo-responsive img {
    width: 100%;
}
.header_mobile {
    display: block;
    height: 55px;
    background: #fff;
}
.logo-section img {
    width: 100%;
}
/* .content-section { */
    /* padding: 0px 0px; */
/* } */
.cabeceras {
    background: #f1f1f1;
    padding: 10px;
    display: none;
}
.cabeceras_actividad {
    background: #f1f1f1;
    padding: 10px;
    display: none;
}
.col20 {
    width: 100%;
    float: left;
    -ms-flex: 0 0 20%;
    flex: 0 0 100%;
    max-width: 100%;
}
.col25 {
    width: 100%;
    float: left;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.footerinfo {
    padding: 40px;
    width: 100%;
    bottom: 0;
    margin: 0;
    text-align: center;
}
.header_mobile .logo-section {
    width: 148px;
    float: left;
    display: none;
}
.dl-menuwrapper .dl-active:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fd4b4b;
    top: 10px;
    left: 16%;
    box-shadow: 0 10px 0 #fd4b4b, 0 20px 0 #fd4b4b;
    transition: all 0.3s;
}
.dl-menuwrapper .dl-menuopen li a {
    display: block;
    position: relative;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #fd4b4b;
    outline: none;
    font-family: 'montserrat';
    background: #fff;
}
.btn_agregar_pregunta {
    border: 1px solid #fd4b4b;
    color: #fff;
    background: #fd4b4b;
    padding: 10px 55px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 20px;
}
.body_prueba_nueva {
    padding: 40px 20px;
    background: #fff;
    margin-bottom: 40px;
    margin: 0 15px 40px 15px;
}
.img_relaciones {
    margin: 30px 0;
    width: 55%;
    margin-right: 40px;
    transition: all 0.3s;
}
.contenedor_restore {
    width: 100%;
    float: none;
    margin: auto;
}
}
@media (min-width: 568px) and (max-width: 736px){
.navbar {
    display: none;
}
.logo-responsive {
    width: 33%;
    float: right;
    position: relative;
    top: -55px;
    display: block;
}
.logo-responsive img {
    width: 100%;
}
.header_mobile {
    display: block;
    height: 55px;
    background: #fff;
}
.logo-section img {
    width: 100%;
}
.content-section {
    padding: 10px 0px;
}
.cabeceras {
    background: #f1f1f1;
    padding: 10px;
    display: none;
}
.cabeceras_actividad {
    background: #f1f1f1;
    padding: 10px;
    display: none;
}
.col20 {
    width: 100%;
    float: left;
    -ms-flex: 0 0 20%;
    flex: 0 0 100%;
    max-width: 100%;
}
.col25 {
    width: 100%;
    float: left;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.footerinfo {
    /* padding: 40px; */
    /* width: 100%; */
    bottom: 0;
    margin: 0;
    text-align: center;
}
.header_mobile .logo-section {
    width: 148px;
    float: left;
    display: none;
}
.dl-menuwrapper .dl-active:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fd4b4b;
    top: 10px;
    left: 16%;
    box-shadow: 0 10px 0 #fd4b4b, 0 20px 0 #fd4b4b;
    transition: all 0.3s;
}
.dl-menuwrapper .dl-menuopen li a {
    display: block;
    position: relative;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #fd4b4b;
    outline: none;
    font-family: 'montserrat';
    background: #fff;
}
.btn_agregar_pregunta {
    border: 1px solid #fd4b4b;
    color: #fff;
    background: #fd4b4b;
    padding: 10px 55px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 40px;
}
.img_relaciones {
    margin: 30px 0;
    width: 40%;
    margin-right: 40px;
    transition: all 0.3s;
}
.body_prueba_nueva {
    padding: 40px 20px;
    background: #fff;
    margin-bottom: 40px;
    margin: 0 15px 40px 15px;
}
.contenedor_restore {
    width: 100%;
    float: none;
    margin: auto;
}
}
@media (min-width: 768px) and (max-width: 800px){
.navbar {
    display: none;
}
.logo-responsive {
    width: 33%;
    float: right;
    position: relative;
    top: -55px;
    display: block;
}
.logo-responsive img {
    width: 100%;
}
.header_mobile {
    display: block;
    height: 55px;
    background: #fff;
}
.logo-section img {
    width: 100%;
}
.content-section {
    padding: 10px 0px;
}
.cabeceras {
    background: #f1f1f1;
    padding: 10px;
    display: none;
}
.cabeceras_actividad {
    background: #f1f1f1;
    padding: 10px;
    display: none;
}
.col20 {
    width: 100%;
    float: left;
    -ms-flex: 0 0 20%;
    flex: 0 0 100%;
    max-width: 100%;
}
.col25 {
    width: 100%;
    float: left;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.footerinfo {
    padding: 40px;
    width: 100%;
    bottom: 0;
    margin: 0;
    text-align: center;
}
.header_mobile .logo-section {
    width: 148px;
    float: left;
    display: none;
}
.dl-menuwrapper .dl-active:after {
    content: '';
    position: absolute;
    width: 68%;
    height: 5px;
    background: #fd4b4b;
    top: 10px;
    left: 16%;
    box-shadow: 0 10px 0 #fd4b4b, 0 20px 0 #fd4b4b;
    transition: all 0.3s;
}
.dl-menuwrapper .dl-menuopen li a {
    display: block;
    position: relative;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #fd4b4b;
    outline: none;
    font-family: 'montserrat';
    background: #fff;
}
.panel_options .col-md-4 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
}
.historial_candidatos .col-md-8 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
}
.historial_candidatos .col-md-4 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
}
.img_relaciones {
    margin: 30px 0;
    width: 40%;
    margin-right: 40px;
    transition: all 0.3s;
}
.body_prueba_nueva {
    padding: 40px 30px;
    background: #fff;
    margin-bottom: 40px;
    margin: 0 15px 40px 15px;
}
.tabs_pruebas .test_tab.active p {
    margin: 0;
    letter-spacing: 0px;
    color: #fff;
}
.tabs_pruebas .test_tab p {
    margin: 0!important;
    letter-spacing: 0px;
    color: #fff!important;
}
.body_prueba_list .modern-Checkbox label {
    color: #363636;
    background-color: #ffffff;
    border-color: #fd4b4b;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 35px;
    font-size: 14px;
    border-radius: 0;
    transition: all 144ms ease-in-out;
    width: 100%;
    letter-spacing: 0;
}
.contenedor_restore {
    width: 100%;
    float: none;
    margin: auto;
}
}
@media (min-width:800px) and (max-width: 1050px){
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 3%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 30rem;
    padding: .5rem 0;
    margin: 0.125rem 0 0;
    font-size: 14px;
    color: #1b2235;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    width: 60%;
}
#sidebarCollapse {
    display: none;
}
#dashboardfull{
    display: none;
}
.body_tabs .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.main .panel h1 {
    font-size: 17px!important;
    color: #959595!important;
    text-transform: uppercase;
    font-family: 'gudea';
    letter-spacing: 2px;
    padding: 0!important;
    margin: 0!important;
    position: relative;
    top: 10px;
}
.panel .content_info p {
    font-size: 28px;
    color: #25344f;
    margin: 0;
    text-align: right;
    width: 50%;
    float: left;
    position: relative;
    top: 0px;
}
.panel .content_info h3 {
    font-size: 28px;
    color: #25344f;
    margin: 0;
    text-align: right;
    width: 50%;
    float: left;
    position: relative;
    top: 0px;
}
.historial_candidatos .col-md-8 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
}
.historial_candidatos .col-md-4 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
}
.filtros_history .col-md-4 {
    padding: 0!important;
}
.img_relaciones {
    margin: 30px 0;
    width: 40%;
    margin-right: 40px;
    transition: all 0.3s;
}
.body_prueba_nueva {
    padding: 40px 30px;
    background: #fff;
    margin-bottom: 40px;
    margin: 0 15px 40px 15px;
}
.tabs_pruebas .test_tab.active p {
    margin: 0;
    letter-spacing: 0px;
    color: #fff;
}
.tabs_pruebas .test_tab p {
    margin: 0!important;
    letter-spacing: 0px;
    color: #fff!important;
}
.body_prueba_list .modern-Checkbox label {
    color: #363636;
    background-color: #ffffff;
    border-color: #fd4b4b;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 35px;
    font-size: 14px;
    border-radius: 0;
    transition: all 144ms ease-in-out;
    width: 100%;
    letter-spacing: 0;
}
.contenedor_restore {
    width: 100%;
    float: none;
    margin: auto;
}
}
.busqueda_prueba .col-md-8 input{
    padding: 10px;
    width: 100%;
    font-size: 14px;
    background: transparent;
    border: 1px solid #cbcbcb;
    transition: all 0.3s;
}
.busqueda_prueba .col-md-6 input:hover{
    border: 1px solid #fd4b4b8c;
    transition: all 0.3s;
}
.busqueda_prueba .col-md-6 input:focus{
    border: 1px solid #fd4b4b8c;
    transition: all 0.3s;
}
.busqueda_prueba {
    /* margin-bottom: 40px; */
}
.buscar_prueba {
    padding: 0px 0px 25px;
    text-align: left;
}
.crear_prueba{
    display : flex;
    justify-content: flex-end;
    margin-bottom : 15px;
}
.btn_buscar_prueba {
    width : 100%;
    border: 1px solid #fd4b4b;
    color: #fff;
    background: #fd4b4b;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
    height : 42px;
}
.btn_buscar_prueba:hover {
    border: 1px solid #fd4b4b;
    color: #fd4b4b;
    background: transparent;
    transition: all 0.3s;
}

.pagination_pruebas .botones_pagination span {
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
    padding: 6px 11px;
    font-size: 12px;
    transition: all 0.3s;
}
.pagination_pruebas .botones_pagination span:hover{
    color: #fff;
    border: 1px solid #fd4b4b;
    background: #fd4b4b;
    padding: 6px 11px;
    font-size: 12px;
    transition: all 0.3s;
}
.pagination_pruebas .botones_pagination .active {
    color: #fff;
    border: 1px solid #fd4b4b;
    background: #fd4b4b;
    padding: 6px 11px;
    font-size: 12px;
    transition: all 0.3s;
}
.pagination_pruebas{
    padding: 15px;
}
.botones_edicion {
    width: 100%;
    text-align: center;
    position: relative;
}
.contenedor_botones {
    width: 27%;
    position: absolute;
    float: right;
    right: 0;
    z-index: 999999;
    padding: 10px;
    opacity: 0;
    transition: all 0.3s;
}
.body_prueba_list:hover .contenedor_botones {
    opacity: 1;
    transition: all 0.3s;
}
.botones_edicion i{
    color: #bdbdbd;
    transition: all 0.3s;
}
.botones_edicion i:hover{
    color: #fd4b4b;
    transition: all 0.3s;
}
.header_prueba {
    width: 180px;
    opacity: 0.7;
    float: none;
    margin: auto;
}
.header_prueba img{
    width: 100%;
}
.allrights_footer_prueba p {
    margin: 0!important;
    text-align: center;
}
@media (max-width: 1000px){
    .MuiDrawer-docked{
        display: none !important;
    }
}

.titlie_main{
    margin : 0px;
    padding : 0px;
}

.container-fluid{
    /* padding of navigation bar in 0 dont show dpace */
    padding-left : 0px !important;
    padding-right : 0px !important;
}

.info_details{
    text-align : center;
    padding: 5px;
    padding-bottom: 5px;
}
.important_marked{
  font-weight: bold;
  font-size: 20px;
  color : red;
}
