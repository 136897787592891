:root {
  --main: #fd4b4b;
  --second: #2ec5d3;
  --gray: #f1f1f1;
}

.main{
    margin-top : 90px !important;
}
.App {
    text-align: center;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Mui-MuiInput{
    background-color : #fd4b4b;
}

.Mui-selected{
    /* se desahilito para la tabla de password */
    color : #FFF !important;
    background-color : #fd4b4b !important;
    /* background-color : rgba(253, 75, 75, 0.1) !important; */
}

.Mui-selected > .MuiListItemIcon-root{
    color : #FFF !important;
}
.MuiBadge-badge{
    background-color : #fd4b4b !important;
}

.MuiListSubheader-root{
    padding-left : 15px !important;
    color : #cbcbcb !important;
    font-weight : bold !important;
    padding-bottom : 0px !important;
    margin-bottom : 0px !important;
    line-height: 30px !important;
    font-size : 12px !important;
}

.MuiContainer-root{
    /* margin-top : 90px !important; */
    max-width : 1777px !important;
    padding : 0px;
}

.panel_card{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding : 90px;
    max-height : 175px;
    /* margin-bottom:20px */
}
.MuiFormControl{
    border : 1px solid #eceff1;
}
.actions_butons_plaza{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: flex-start;
    padding : 10px;
}

.activar_vista{
    display : block !important;
    transition: opacity 0.5s;
    opacity: 1;
}
.desactivar_vista{
    display : none !important;
    opacity: 0;
    pointer-events:none;
}
.grid_btn{
    font-size : 20px !important;
    color: #b9c3ce;
    transition: all 0.3s;
    cursor: pointer;
}
.error_feedback{
    font-size: 13px;
    color: #2ec5d3;
    font-weight :bold;
    padding-top : 5px;
    margin: 0px;
}
.content-section{
    min-height:100vh !important;
}


#todas_las_pruebas_body{
    display : block;
}
#comportamiento_body{
    display : none;
}
#personalidad_body{
    display : none;
}
#inteligencia_body{
    display : none;
}
#container_btn_agregar_pregunta{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
}

/* .MuiButton-root:hover{ */
    /* background-color : #fd4b4b !important; */
/* } */

.css-ghsjzk-MuiInputBase-root-MuiInput-root:before{
    border : none !important;
}
.login_link{
    color : #2ec5d3;
}
.registerBtn{
    background-color: #fd4b4b !important;
}
.login_link:hover{
    font-weight : bold;
    color : #3ec5d3;
}
.MuiStepIcon-root.Mui-completed{
    color: #fd4b4b !important;
}
.MuiStepIcon-root.Mui-active {
    color: #fd4b4b !important;
}

.card_register{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;
    padding : 35px; 
}
.register_content{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items :stretch;
}

#password_header{
    margin-bottom: 20px;
    padding-bottom: 20px;
}
#pass_footer_row{
    display: flex;
}

.contras_operation{
    display : flex;
    flex-direction:row;
}
.feedback_error{
    color: #fd4b4b;
    font-size:14px;
}

#login_btn_fn:hover{
    background-color: var(--second);
}

.btn_plaza_common{
    cursor:pointer;
}

.btn_plaza_common:hover{
    color:var(--main) !important;
}
.center_pagination_puestos{
    display :flex;
    flex-direction :row;
}

.menu-shit{
    padding-top:57px;
}

.w3-sidebar{height:100%;width:200px;background-color:#fff;position:fixed!important;z-index:1;overflow:auto}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}.w3-auto{max-width:100%}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-hide-large{display:none!important}.w3-sidebar.w3-collapse{display:block!important}}


.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}

.w3-border-left{border-left:1px solid #ccc!important}.w3-border-right{border-right:1px solid #ccc!important}

#mySidebar{
    z-index : 999;
}

#loading_or_content{
    display: grid;
    min-height:100vh;
    place-content:center;
}

#pagi_bottom{
  margin-left:15px;
  margin-right:15px;
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:baseline;
  justify-content:space-between;
  flex-wrap:wrap;
}

.css-19kzrtu{
    padding:0px !important;
    padding-top : 25px !important;
}

.name_header{
    max-width:20px;
    font-weight:bold;
}
.title_td{
    font-weight: bold;
}
.operation_buttons{
    display: flex;
    flex-direction:row;
}
.icon_op{
    border : 1px solid var(--main);
    color : var(--main);
    margin :5px;
}

.test_list{
    max-height: 500px;
    display : flex;
}
.card_test{
    padding : 15px;
}
#start_title{
    padding-bottom:20px;
}
.prueba_body{
  padding: 15px;
  background: r;
  background-color: red;
  width: '100%';
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;}

.quiz_title_text{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline !important;
  justify-content: flex-start;
}

.checkbox_label_question{
  font-weight:bold !important;
}

.question{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding : 100px;
}

.question_btn{
  display:flex;
  justify-content: center;
  padding-top:35px;
  padding-bottom:35px;
  margin-bottom:35px;
}
.nextquestion_btn{
  margin-left:5px !important;
}
.nextquestion_btn:hover{
  background-color: var(--main) !important;
}

.card_cleaver_btn{
  margin-right:20px;
  padding-right:20px
}
.question_body{
  display: grid;
}
.question_form{
  grid-column: 1;
  grid-row: 1;
  min-width: 275;
  /* margin: 10px !important;  */
  /* border-left: 5px solid var(--main); */
}
.table_password th{
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: var(--main);
  color: white;
}
.loadingGeneric{
    height: 100vh;
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

#search_plaza_input{
  padding-left:20px !important;
  margin-left:20px !important;
}

.chip_and_search{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
}
.table_list_test_user{
  border :none !important;
}
.pwd_user_name{
  border-bottom:none!important;
  border-right :1px solid #f1f1f1 !important;
  /* background:red; */
}
.home_letter{
  font-family: 'gudea' !important;
  color: #878787 !important;
  line-height: 1 !important;
  font-size: 14px !important;
}
.asign_status{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
}
.pwd_info{
  border-right:1px solid #f1f1f1;
  display: grid;
  justify-items: stretch;
  align-items: stretch;
}
.btn_pwd{
  color: black !important;
background: rgb(253,75,75) !important;
background: none !important;
  box-shadow: none !important;
}

.headert_pwdu{
  height :10px;
  background:var(--main);
  color:white !important;
  font-weight:bold! important;
}
.bbottom{
  margin:0 !important;
  padding:0 !important;
  /* border-top: 1px solid var(--bs-gray-200); */
}
.cardmedia_puesto{
  background-color: var(--second);
  object-fit: none;
  object-position: left top;
  border: 2px solid black;
}
.seluser_type{
  margin:3px solid black;
  margin-bottom: 5px;
}
.rolelist{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.demo-radio-buttons-group-label{
  font-weight: bold;
  padding-top:10px !important;
  padding-bottom:10px !important;
}
.typepwdlist{
  border-bottom: 1px solid gainsboro;
  /* padding: 15px; */
}
.titleMarked{
  /* color : gray; */
}
.gapwdrole{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.control_flow_role{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.loadingBase{
  display: grid;
}
.lodingSpinner{
  place-self: center;
}


.text_underline:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: blue;
}

/* CSS */
.text_underline {
  transition: color 0.3s ease; /* Transición suave del color durante 0.3 segundos */
  animation: fadeIn 1s ease; /* Aplicar la animación de fade-in */
}


